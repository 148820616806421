import React, {Component} from 'react';
import {Button, Carousel} from "react-bootstrap";
import "./index.css";
import Logo from "./MinecraftLogo.png";
import HWMCLogo from "./helloworldmc.png"

class Buttons extends Component {
    render() {
        return (
            <div>
                <Carousel.Caption>

                    <img src={Logo} alt="logo"/>

                    <img src={HWMCLogo} alt="logo" className="serverName"/>
                    <br/>
                    <br/>

                    <Button href="http://map.ee-fans.com" variant="outline-light" size="lg">地图 Map</Button>
                    <br/>

                    <br/>
                    <Button href="/join.html" variant="outline-light" size="lg">加入我们 Join US</Button>
                </Carousel.Caption>
            </div>
        );
    }
}

export default Buttons;
import React, {Component} from 'react';
import {Button, Carousel, Container, Image, Row} from "react-bootstrap";
import "./index.css";

const imgs = [
    "https://i.loli.net/2021/02/16/hLmWptFANVG35eb.png",
    "https://i.loli.net/2021/02/16/fCgyFZQ3lVb7YNB.png",
    "https://i.loli.net/2021/02/16/WQn5FPpoxdl1MSR.png",
    "https://i.loli.net/2021/02/16/nBgZhyqmpzcsteK.png"
];

class Background extends Component {

    render() {

        return (
            <Carousel interval={1500} nextIcon={null} prevIcon={null} indicators={false}>
                <Carousel.Item>
                    <img
                    className="d-block w-100 vh-100"
                    src={imgs[0]}
                    alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 vh-100"
                        src={imgs[1]}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 vh-100"
                        src={imgs[2]}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 vh-100"
                        src={imgs[3]}
                        alt="Second slide"
                    />
                </Carousel.Item>
            </Carousel>
        )
    }
}

export default Background;
import React, {Component} from 'react';
import {Container, Row} from "react-bootstrap";
import Background from "./components/background";
import Buttons from "./components/buttons";

class App extends Component {
    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Background/>
                        <Buttons/>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default App;